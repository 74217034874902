import { formatDate } from './date-utils';

export function getNow() {
  return new Date();
}

export function getNowTimestamp() {
  return new Date().getTime();
}

export function getToday() {
  const now = getNow().setHours(0, 0, 0, 0);
  return new Date(now);
}

export function getTodayString() {
  const now = getToday();
  const formatted = formatDate(now);
  return formatted as string;
}
