import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInDays,
  format,
} from 'date-fns';
import {
  BudgetDocument,
  BudgetFrequency,
  FillDay,
  GroupDocument,
  GroupDocumentWithSubscription,
} from '@og-shared/types';
import { exhaustiveCheck } from './utils';
import { getToday, getTodayString } from './today';

export function getPaidStatusFromDate(
  expiresDateString: string
): Extract<
  GroupDocumentWithSubscription['status'],
  'active' | 'canceled' | 'expiring'
> {
  const todayString = getTodayString();
  const thirtyDaysFromToday = formatDate(addDays(getToday(), 30));
  if (expiresDateString < todayString) {
    return 'canceled';
  }
  if (expiresDateString < thirtyDaysFromToday) {
    return 'expiring';
  }
  return 'active';
}

export function getBudgetFrequencyFromDates(
  dateStrings: string[]
): BudgetFrequency {
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 365, buffer: 4 })) {
    // yearly
    return 1;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 182, buffer: 3 })) {
    // every 6 months
    return 2;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 91, buffer: 3 })) {
    // every 3 months
    return 4;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 61, buffer: 3 })) {
    // every 2 months
    return 6;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 30, buffer: 3 })) {
    // every 1 month
    return 12;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 14, buffer: 0 })) {
    // every two weeks
    return 26;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 15, buffer: 1 })) {
    // twice per month
    return 24;
  }
  if (areDatesWithinDays({ dateStrings, daysBetweenDates: 7, buffer: 1 })) {
    // every week
    return 52;
  }
  return 0;
}

export function areDatesWithinDays(params: {
  dateStrings: string[];
  daysBetweenDates: number;
  buffer: number; // +/- n days
}) {
  const { dateStrings, daysBetweenDates, buffer } = params;
  const minDays = daysBetweenDates - buffer;
  const maxDays = daysBetweenDates + buffer;

  if (dateStrings.length < 2) return false;
  let count = 0;
  for (var i = 0; i < dateStrings.length - 1; i++) {
    const firstDate = dateStrings[i];
    const secondDate = dateStrings[i + 1];
    const days = Math.abs(
      differenceInDays(
        getDateAtMidnight(firstDate),
        getDateAtMidnight(secondDate)
      )
    );

    if (minDays <= days && days <= maxDays) {
      count++;
    }
  }
  const ratio = count / (dateStrings.length - 1);
  return ratio > 0.5;
}

export function getNextFillDate(
  startDateString: string,
  currentFillDay: GroupDocument['fill_day']
) {
  let fillDay = currentFillDay;
  const startDate = getDateAtMidnight(startDateString);
  // console.log('current fill Date', startDate);
  const startDay = new Date(startDate).getDay();
  if (fillDay <= startDay) {
    fillDay += 7;
  }
  const daysToFillDay = fillDay - startDay;
  // console.log('days to fill', daysToFillDay);

  const nextFillDate = addDays(startDate, daysToFillDay);
  // console.log('next Fill Date', nextFillDate);
  return nextFillDate;
}

export function countFillDays(
  startString: string,
  endString: string,
  fillDay: FillDay
) {
  const startDate = startString;
  const nextFillDate = formatDate(getNextFillDate(startDate, fillDay));
  const start = getDateAtMidnight(nextFillDate);
  const end = getDateAtMidnight(endString);
  const ndays = differenceInCalendarDays(end, start);
  // because we're starting at the next fill date
  // we can count the number of full weeks since then.
  let totalCount = Math.floor(ndays / 7);
  if (ndays >= 0) {
    totalCount++;
  }
  return totalCount;
}

export function getNextDate(
  dateString: string,
  per_year: Exclude<BudgetDocument['per_year'], 0>,
  fill_days?: BudgetDocument['fill_days']
) {
  const date = getDateAtMidnight(dateString);
  switch (per_year) {
    case 1: {
      return formatDate(addYears(date, 1));
    }
    case 2: {
      return formatDate(addMonths(date, 6));
    }
    case 4: {
      return formatDate(addMonths(date, 3));
    }
    case 6: {
      return formatDate(addMonths(date, 2));
    }
    case 12: {
      return formatDate(addMonths(date, 1));
    }
    case 24: {
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDay = fill_days?.[0] ?? 1;
      const secondDay = fill_days?.[1] ?? 15;
      const firstDate = new Date(year, month, firstDay);
      const secondDate = new Date(year, month, secondDay);

      if (date >= firstDate) {
        if (date >= secondDate) {
          return formatDate(addMonths(firstDate, 1));
        } else {
          return formatDate(secondDate);
        }
      } else {
        return formatDate(firstDate);
      }
    }
    case 26: {
      return formatDate(addWeeks(date, 2));
    }
    case 52: {
      return formatDate(addWeeks(date, 1));
    }
    default: {
      exhaustiveCheck(per_year);
    }
  }
  return formatDate(date);
}

function getPreviousDate(
  dateString: string,
  per_year: Exclude<BudgetDocument['per_year'], 0>,
  fill_days?: BudgetDocument['fill_days']
) {
  const date = getDateAtMidnight(dateString);
  switch (per_year) {
    case 1: {
      return formatDate(addYears(date, -1));
    }
    case 2: {
      return formatDate(addMonths(date, -6));
    }
    case 4: {
      return formatDate(addMonths(date, -3));
    }
    case 6: {
      return formatDate(addMonths(date, -2));
    }
    case 12: {
      return formatDate(addMonths(date, -1));
    }
    case 24: {
      const year = date.getFullYear();
      const month = date.getMonth();
      const firstDay = fill_days?.[0] ?? 1;
      const secondDay = fill_days?.[1] ?? 15;
      const firstDate = new Date(year, month, firstDay);
      const secondDate = new Date(year, month, secondDay);

      if (date <= firstDate) {
        if (date <= secondDate) {
          return formatDate(addMonths(firstDate, -1));
        } else {
          return formatDate(secondDate);
        }
      } else {
        return formatDate(firstDate);
      }
    }
    case 26: {
      return formatDate(addWeeks(date, -2));
    }
    case 52: {
      return formatDate(addWeeks(date, -1));
    }
    default: {
      exhaustiveCheck(per_year);
    }
  }
  return formatDate(date);
}

export function getNextDateAfterMinDate(params: {
  startDateString: string;
  budget: {
    per_year: Exclude<BudgetDocument['per_year'], 0>;
    fill_days: BudgetDocument['fill_days'];
  };
  minDateString: string;
}): string {
  const { startDateString, budget, minDateString } = params;
  if (startDateString > minDateString) {
    // already past the date specified
    // is it more than one period past?
    let runningDate = startDateString;
    let iterations = 0;
    while (true) {
      // keep going back until we've passed the minDate
      const previousDate = getPreviousDate(
        runningDate,
        budget.per_year,
        budget.fill_days
      );
      if (previousDate < minDateString) {
        // past min date - use last value
        break;
      }
      runningDate = previousDate;
      if (iterations === 10) {
        console.error('failed getting previous date');
        break;
      }
    }
    return runningDate;
  }
  const nextDate = getNextDate(
    startDateString,
    budget.per_year,
    budget.fill_days
  );
  if (nextDate == startDateString) {
    console.error('error with this budget', budget);
    return nextDate;
  }

  if (nextDate && nextDate <= minDateString) {
    return getNextDateAfterMinDate({
      startDateString: nextDate,
      budget,
      minDateString,
    });
  } else {
    return nextDate;
  }
}

export function formatDate(date: Date) {
  const formatted = format(date, 'yyyy-MM-dd');
  return formatted;
}

export function getDateAtMidnight(dateString: string) {
  const dateArray = dateString.split(/\D/);
  return new Date(
    Number(dateArray[0]),
    Number(dateArray[1]) - 1,
    Number(dateArray[2] ?? 1)
  );
}

export function formatDateFromString(dateString: string) {
  if (!dateString) return null;
  const date = getDateAtMidnight(dateString);
  const formatted = format(date, 'M/d/yy');
  return formatted;
}

export function getUnitCount(params: {
  frequency: 1 | 12 | 52 | 365;
  startDate: string;
  endDate: string;
}) {
  const { startDate, endDate, frequency } = params;
  switch (frequency) {
    case 1: {
      const years = differenceInCalendarYears(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return years + 1;
    }
    case 12: {
      const months = differenceInCalendarMonths(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return months + 1;
    }
    case 52: {
      const weeks = differenceInCalendarWeeks(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return weeks + 1;
    }
    case 365: {
      const days = differenceInCalendarDays(
        getDateAtMidnight(endDate),
        getDateAtMidnight(startDate)
      );
      return days + 1;
    }
    default: {
      return exhaustiveCheck(frequency);
    }
  }
}

export function getTrialNoCCStatusFromDate(
  expiresDateString: string | null
): Extract<
  GroupDocumentWithSubscription['status'],
  'trialing_no_cc' | 'canceled' | 'expiring'
> {
  if (!expiresDateString) {
    return 'trialing_no_cc';
  }
  const todayString = getTodayString();
  const fourteenDaysFromToday = formatDate(addDays(getToday(), 14));
  if (expiresDateString < todayString) {
    return 'canceled';
  }
  if (expiresDateString < fourteenDaysFromToday) {
    return 'expiring';
  }
  return 'trialing_no_cc';
}

export function getDateString(date: Date) {
  const dateString = format(date, 'yyyy-MM-dd');
  return dateString;
}

export function getDatesFromSegment(segment: 1 | 2 | 4 | 12) {
  const today = getToday();
  const startDate = formatDate(getToday());
  switch (segment) {
    case 1:
      return {
        endDate: formatDate(addMonths(today, 14)),
        startDate,
      };
    case 2:
      return {
        endDate: formatDate(addMonths(today, 6)),
        startDate,
      };
    case 4:
      return {
        endDate: formatDate(addMonths(today, 3)),
        startDate,
      };
    case 12:
      return {
        endDate: formatDate(addMonths(today, 1)),
        startDate,
      };
    default:
      exhaustiveCheck(segment);
      return {
        endDate: formatDate(addMonths(today, 14)),
        startDate,
      };
  }
}
